import request from '@/utils/request'

/* 角色列表 */
export function getRoles(sn,params) {
  return request({
    url: `/console/surveys/${sn}/roles`,
    method: 'GET',
    params
  })
}

/* 团队成员列表 */
export function getTeamUser(sn,params) {
  return request({
    url: `/console/surveys/${sn}/team_users`,
    method: 'GET',
    params
  })
}

/* 团队分组列表 */
export function getTeamGroup(sn,params) {
  return request({
    url: `/console/surveys/${sn}/team_groups`,
    method: 'GET',
    params
  })
}

/* 邀请-成员/分组 */
export function surveyRoles(sn,data) {
  return request({
    url: `/console/surveys/${sn}/survey_roles`,
    method: "POST",
    data,
  });
}

/* 生成code */
export function linkCodes(sn,data) {
  return request({
    url: `/console/surveys/${sn}/link_codes`,
    method: "POST",
    data,
  });
}

/* 链接邀请 */
// export function surveyRolesLinks(data) {
//   return request({
//     url: `/console/survey_role_links`,
//     method: "POST",
//     data,
//   });
// }

/* 所有者/协作者展示 */
export function getRoleUsers(sn,params) {
  return request({
    url: `/console/surveys/${sn}/role_users`,
    method: 'GET',
  })
}

/* 所有者/协作者展示 */
export function getRoleUsersAll(sn,params) {
  return request({
    url: `/console/surveys/${sn}/role_users_all`,
    method: 'GET',
  })
}
/* 变更权限 */
export function changePermissions(sn, data) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${sn}/role_users`,
    data,
  });
}

/* 移除权限 */
export function deletePermissions(sn,params) {
  return request({
    url: `/console/surveys/${sn}/role_users`,
    method: 'DELETE',
    params,
  });
}

/* 设为所有者 */
export function changeOwners(sn, data) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${sn}/owners`,
    data,
  });
}
